<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div
        style="
          display: flex;
          flex-direction: column;
          height: calc(100vh - 180px);
        "
      >
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item" v-if="search">
            <label class="fn-14">关键字</label>
            <el-input
              v-model="searchKeyword"
              placeholder="请输入关键字"
              style="width: 202px; margin-right: 20px"
              clearable
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item" v-if="search">
            <label class="fn-14">响应状态</label>
            <el-select
              v-model="searchResponseState"
              placeholder="请选择响应状态"
              style="width: 202px; margin-right: 20px"
              clearable
              autocomplete="off"
              size="small"
            >
              <el-option
                v-for="item in responseStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="condition-item" v-if="search">
            <label class="fn-14">日志类型</label>
            <el-select
              v-model="searchLogType"
              placeholder="请选择日志类型"
              style="width: 202px; margin-right: 20px"
              clearable
              autocomplete="off"
              size="small"
            >
              <el-option
                v-for="item in logTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="condition-item" v-if="search">
            <el-button
              v-if="search"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              size="small"
              >查询</el-button
            >
            <el-button
              v-if="search"
              type="info"
              icon="el-icon-refresh"
              @click="resetSearch"
              size="small"
              >重置</el-button
            >
          </div>
        </div>

        <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
          <el-table
            :data="logList"
            v-loading="tableLoading"
            stripe
            border
            height="100%"
            style="width: 100%"
          >
            <el-table-column fixed prop="sourceIp" label="来源IP" width="200">
            </el-table-column>
            <el-table-column
              prop="sourceBrowser"
              label="来源浏览器"
              width="100"
            >
            </el-table-column>
            <el-table-column prop="requestUrl" label="请求URL" width="400">
            </el-table-column>
            <el-table-column prop="requestMethod" label="请求方法" width="100">
            </el-table-column>
            <el-table-column
              prop="requestData"
              label="请求数据"
              width="400"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="requestTime" label="请求时间" width="200">
            </el-table-column>
            <el-table-column prop="executeMethod" label="执行方法" width="400">
            </el-table-column>
            <el-table-column prop="responseState" label="响应状态" width="100">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.responseState === 1"
                  type="success"
                  size="small"
                  >成功</el-tag
                >
                <el-tag v-else type="danger" size="small">失败</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="responseData"
              label="响应数据"
              width="400"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="executeMsg" label="执行信息" width="200">
            </el-table-column>
            <el-table-column prop="executeTime" label="执行耗时" width="100">
            </el-table-column>
            <el-table-column
              prop="exceptionMsg"
              label="异常信息"
              width="400"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="logType" label="日志类型" width="100">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.logType === 1" type="success"
                  >登录日志</el-tag
                >
                <el-tag v-if="scope.row.logType === 2" type="info"
                  >操作日志</el-tag
                >
                <el-tag v-if="scope.row.logType === 3">工行日志</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="userId" label="用户ID" width="100">
            </el-table-column>
            <el-table-column prop="userName" label="用户名称" width="100">
            </el-table-column>
          </el-table>
        </div>

        <div style="display: flex; justify-content: right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { queryLog } from "@/api/log";
import { dateFormat } from "@/utils/date";
import { queryPageButton } from "@/api/permission";

export default {
  name: "LogQuery",
  data() {
    return {
      searchKeyword: "",
      searchResponseState: "",
      searchLogType: "",
      logList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      responseStateOptions: [
        {
          label: "失败",
          value: 0,
        },
        {
          label: "成功",
          value: 1,
        },
      ],
      logTypeOptions: [
        {
          label: "登录日志",
          value: 1,
        },
        {
          label: "操作日志",
          value: 2,
        },
        // {
        //   label: "工行日志",
        //   value: 3,
        // },
      ],
      search: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 15;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.search = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;
      const data = {
        keyword: this.searchKeyword,
        responseState: this.searchResponseState,
        logType: this.searchLogType,
      };

      queryLog(this.currentPage, this.pageSize, data).then((resp) => {
        const content = resp.data.content;
        for (const item of content) {
          item.requestTime = dateFormat(
            "YYYY-mm-dd HH:MM:SS",
            new Date(item.requestTime)
          );
          item.executeTime = item.executeTime + " 毫秒";
        }
        this.logList = content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchKeyword = "";
      this.searchResponseState = "";
      this.searchLogType = "";
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
